import Rails from "rails-ujs";

document.addEventListener("DOMContentLoaded", function () {
	var all_checkboxes = document.getElementsByClassName('row-checkbox');
	for (var i = 0; i < all_checkboxes.length; i++) {
		all_checkboxes[i].addEventListener('click', (event) => {
			var form = document.querySelector('#participant_form');
			Rails.fire(form, 'submit')
			event.target.replaceWith(('Added'));
		});
	}
});
