document.addEventListener("DOMContentLoaded", function () {
  const completeSessionButton = document.getElementById('complete-session');
  const NUMBER_OF_QUESTIONS_REQUIRED = 6;

  if (completeSessionButton) {
	completeSessionButton.addEventListener('click', (event) => {
	  radio_buttons_number = document.querySelectorAll('.survey-col input[type="radio"]:checked').length;
	  region_result_number = document.querySelectorAll('.survey-col .survey_form_region_question_result input[type="checkbox"]:checked').length;
	  organisation_result_number = document.querySelectorAll('.survey-col .survey_form_organisation_question_result input[type="checkbox"]:checked').length;
	  total_number = radio_buttons_number + region_result_number + organisation_result_number;

	  if (total_number >= NUMBER_OF_QUESTIONS_REQUIRED && radio_buttons_number > 0 && region_result_number > 0 && organisation_result_number > 0) {
		  let result = confirm('Are you sure you want to complete the training?');
		  if (!result){
			  event.preventDefault();
			  event.stopPropagation();
		  }
	  } else {
		  event.preventDefault();
		  event.stopPropagation();
		  alert('Please complete all survey questions.');
		}
	});
  }
});
